import moment from '../utils/moment';
import { SaveLogSession } from '../utils/requests/logConnexion';
import { ActionTypes, StoreInterface, UserInterface } from './userProvider';

interface UserSettings {
    isOpen: boolean;
    selectedMenuId?: number;
}

interface ActionInterface {
    type: string;
    payload?: {
        user: UserInterface;
        jtw: string;
        isTokenRefresherOpen?: boolean;
        userSettings?: UserSettings;
    };
}

export const UserReducer = (state: StoreInterface, action: ActionInterface): any => {
    const userInfos = action.payload?.user;
    const jwtToken = action.payload?.jtw;
    switch (action.type) {
        // LOGIN PATIENT ACTION
        case ActionTypes.login:
            jwtToken !== undefined && sessionStorage.setItem('jwtToken', jwtToken);
            return {
                userInfos: undefined,
                isAuthorized: false,
                jwtToken: jwtToken,
            };
        // LOGOUT ACTION
        case ActionTypes.logout:
            const userRole: string|null = localStorage.getItem('userRole');
            
            const logConnexions = state?.userInfos?.log_connexions
            const lastConnexion = logConnexions && logConnexions[logConnexions?.length - 1]

            try {
                lastConnexion && SaveLogSession({
                    id: lastConnexion?.id,
                    user_id: state?.userInfos?.id,
                    startDate: lastConnexion?.startDate,
                    endDate: moment().format("YYYY-MM-DDTHH:mm:ss.sssZ")
                }).finally(() => {
                    sessionStorage?.removeItem('jwtToken');
                    if(userRole === "Medecin") {
                        document.location.href = "/login-ps";
                    }else{
                        document.location.href = "/login";
                    }
                })
                return {...state, userInfos: undefined, isAuthorized: false, jwtToken: undefined };
            } catch (error: any) {
                console.error(error.response?.data?.error || error?.message)
                if(userRole === "Medecin") {
                    document.location.href = "/login-ps";
                }else{
                    document.location.href = "/login";
                }
                sessionStorage?.removeItem('jwtToken');
                return { ...state, userInfos: undefined, isAuthorized: false, jwtToken: undefined }
            }
        // LOAD USER ACTION
        case ActionTypes.userLoaded:
            localStorage.setItem('userRole', userInfos?.role.name || "");
            return { ...state, 
                userInfos, 
                isAuthorized: userInfos?.isOnline,
                startSession: state?.startSession === undefined ? moment().format("YYYY-MM-DDTHH:mm:ss.sssZ") : state?.startSession };
        // Confirm auth ACTION
        case ActionTypes.authConfirm:
            jwtToken !== undefined && sessionStorage.setItem('jwtToken', jwtToken);
            return { 
                ...state, 
                userInfos, 
                isAuthorized: false,
                jwtToken: jwtToken
            };
        // Update user data ACTION
        case ActionTypes.updateUserData:
            return { 
                ...state, 
                userInfos
            };
        // Open token refresher modal ACTION
        case ActionTypes.openTokenRefresher:
            const newValue = action?.payload?.isTokenRefresherOpen;
            return { 
                ...state, 
                isTokenRefresherOpen: newValue
            };
        // Open settings modal ACTION
        case ActionTypes.openSettings:
            const openSettings = action?.payload?.userSettings?.isOpen;
            const selectedMenuId = action?.payload?.userSettings?.selectedMenuId;
            return { 
                ...state, 
                userSettings: {
                    isOpen: openSettings,
                    selectedMenuId: openSettings === true ? (selectedMenuId || 0) : 0
                }
            };
        // DEFAULT ACTION
        default:
            return state;
    }
};
