import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react'
import D_Logo from '../../assets/media/logo/Logo_horizontal.svg';

import { Fragment, JSXElementConstructor, ReactElement, useEffect, useState } from 'react';
import './modal.scss';
import { LiaTimesSolid } from "react-icons/lia";

interface CustomModalInterface {
  open: boolean,
  title: ReactElement<any, string | JSXElementConstructor<any>> | string ,
  image: ReactElement<any, string | JSXElementConstructor<any>>,
  children: ReactElement<any, string | JSXElementConstructor<any>>,
  footer: ReactElement<any, string | JSXElementConstructor<any>>,
  closable: boolean,
  onClose: () => void,
}

export const CustomModal = (props: CustomModalInterface) => {
  const {open, image, title, children, footer, closable} = props
  let [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    if(props?.open === false && isOpen === true) {
      setIsOpen(false)
    }
  }, [props?.open, isOpen]);

  return (
    <>
      <Transition
        appear show={isOpen} as={Fragment}
      >
        <Dialog 
          open={isOpen} 
          onClose={() => {
            if(closable){
              props?.onClose();
              setIsOpen(false)
            }
          }}
          as="div" 
          className="relative z-[1001]"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-black/40" />
          </Transition.Child>
          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 overflow-y-auto">
            {/* Container to center the panel */}
            <div className="flex w-full min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
              <Dialog.Panel className="flex w-full flex-col md:min-w-[600px] justify-start items-start gap-3 mx-auto max-w-sm rounded-lg bg-white p-4 md:p-6 lg:p-8">
                {/* Header of the panel */}
                <div className="flex w-full justify-between items-center">
                  <img
                    className="h-6 w-auto"
                    src={D_Logo}
                    alt="Lianeli"
                  />
                  <div className="clear w-2"></div>
                  {closable && (
                    <button 
                      type="button"
                      onClick={() => {
                        props?.onClose();
                        setIsOpen(false)
                      }}
                      className="rounded-full px-1 py-1 shadow-sm border--box">
                      <>
                        <LiaTimesSolid className="text-sm" />
                      </>
                    </button>
                  )}
                </div>
                
                {/* Image container */}
                {image && (
                <div className="flex flex-col justify-start items-start gap-3 mx-auto max-w-sm rounded">
                  {image}
                </div>
                 )}

                {/* Title container */}
                {title && (
                <Dialog.Title
                as="h4"
                className="font--poppins font-bold leading-6 text-[15px] md:text-md text--default pt-2 w-full"
              >
                {title}
              </Dialog.Title>
                )}

                {/* children container */}
                <div className="flex flex-col w-full justify-start items-center max-h-[200px] md:max-h-[350px] overflow-auto">
                  {children}
                </div>

                {/* footer container */}
                <div className={`flex w-full ${!footer && "pt-0"}`}>
                  {footer}
                </div>
              </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

CustomModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node || PropTypes.string,
  image: PropTypes.node || PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.node,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
};

CustomModal.defaultProps = {
  open: true,
  title: "",
  image: undefined,
  children: undefined,
  footer: undefined,
  closable: true,
  onClose: () => null,
};