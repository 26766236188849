/*
 * AUTH URLS
 */
const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/local`;
const EMAIL_CONFIRMATION = `${process.env.REACT_APP_API_URL}/auth/email-confirmation`;
const CHECK_RESET_TOKEN = `${process.env.REACT_APP_API_URL}/auth/check-reset-token`;
const FORGOT_PASSWORD = `${process.env.REACT_APP_API_URL}/auth/forgot-password`;
const GET_LINK_WITH_DOCTOR = `${process.env.REACT_APP_API_URL}/patients/get-link-with-doctor`;
const RESET_PASSWORD = `${process.env.REACT_APP_API_URL}/auth/reset-password`;
const SEND_AUTH_CODE = `${process.env.REACT_APP_API_URL}/auth/send-auth-code`;
const CHECK_AUTH_CODE = `${process.env.REACT_APP_API_URL}/auth/check-auth-code`;
const RENEW_TOKEN = `${process.env.REACT_APP_API_URL}/auth/renew-token`;

/*
 * USERS URLS
 */
const USERS_URL = `${process.env.REACT_APP_API_URL}/users`;
const ME_URL = `${process.env.REACT_APP_API_URL}/users/me`;

/*
 * DOCTORS URLS
 */
const DOCTORS_URL = `${process.env.REACT_APP_API_URL}/doctors`;

/*
 * PATIENTS URLS
 */
const PATIENTS_URL = `${process.env.REACT_APP_API_URL}/patients`;

/*
 * THEMES URLS
 */
const THEMES_URL = `${process.env.REACT_APP_API_URL}/form-themes`;

/*
 * QUESTIONS URLS
 */
const QUESTIONS_URL = `${process.env.REACT_APP_API_URL}/form-questions`;

/*
 * RESULTS URLS
 */
const RESULTS_URL = `${process.env.REACT_APP_API_URL}/form-results`;

/*
 * PATIENT EXAMS URLS
 */
const PATIENT_EXAMS_URL = `${process.env.REACT_APP_API_URL}/patient-exams`;

/*
 * FORM_SESSIONS URLS
 */
const FORM_SESSIONS_URL = `${process.env.REACT_APP_API_URL}/form-sessions`;

/*
 * PATIENT_RDVS URLS
 */
const PATIENT_RDVS_URL = `${process.env.REACT_APP_API_URL}/patient-rdvs`;

/*
 * NOTIFICATIONS URLS
 */
const NOTIFICATIONS_URL = `${process.env.REACT_APP_API_URL}/notifications`;

/*
 * LOGS CONNEXION
 */
const LOG_CONNEXION_URL = `${process.env.REACT_APP_API_URL}/log-connexions`;

/*
 * SHARED_INVITATIONS_URL
 */
const SHARED_INVITATIONS_URL = `${process.env.REACT_APP_API_URL}/shared-invitations`;

/*
 * SCREENING THEMES
 */
const SCREENING_THEMES_URL = `${process.env.REACT_APP_API_URL}/screening-themes`;

/*
 * DOCTOR COMMENTS
 */
const DOCTOR_COMMENTS_URL = `${process.env.REACT_APP_API_URL}/doctor-comments`;

/*
 * DOCTOR LINKS
 */
const DOCTOR_LINKS_URL =  `${process.env.REACT_APP_API_URL}/doctor-links`;

/*
 * PATIENT_REPORT
 */
const PATIENT_REPORT_URL = `${process.env.REACT_APP_API_URL}/patient-reports`;
/*
 * ORGANIZATION_SUBSCRIPTIONS 
 */
const ORGANIZATION_SUBSCRIPTIONS = `${process.env.REACT_APP_API_URL}/organization-subscriptions`;

/*
 * ORGANIZATIONS INFORMATIONS 
 */
const ORGANIZATIONS = `${process.env.REACT_APP_API_URL}/organizations`;

/*
 * TIME DISPONIBILITIES 
 */
const TIME_DISPONIBILITIES = `${process.env.REACT_APP_API_URL}/time-disponibilities`;

/*
 * SCREENING FILES
 */
const SCREENING_FILES = `${process.env.REACT_APP_API_URL}/screening-files`;

/*
 * FAQ CATEGORIES
 */
const FAQ_CATEGORIES = `${process.env.REACT_APP_API_URL}/faq-categories`;

/*
 * USER REQUESTS
 */
const USER_REQUESTS = `${process.env.REACT_APP_API_URL}/user-requests`;

/*
 * PATIENTS SETTINGS
 */
const PATIENT_SETTINGS_URL = `${process.env.REACT_APP_API_URL}/patient-settings`;

/*
 * PATIENTS SETTINGS
 */
const PARTNERS = `${process.env.REACT_APP_API_URL}/partners`;

export {
    LOGIN_URL,
    ME_URL,
    EMAIL_CONFIRMATION,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    CHECK_RESET_TOKEN,
    SEND_AUTH_CODE,
    CHECK_AUTH_CODE,
    RENEW_TOKEN,
    USERS_URL,
    PATIENTS_URL,
    PATIENT_RDVS_URL,
    DOCTORS_URL,
    THEMES_URL,
    QUESTIONS_URL,
    RESULTS_URL,
    PATIENT_EXAMS_URL,
    FORM_SESSIONS_URL,
    NOTIFICATIONS_URL,
    SHARED_INVITATIONS_URL,
    SCREENING_THEMES_URL,
    DOCTOR_COMMENTS_URL,
    DOCTOR_LINKS_URL,
    PATIENT_REPORT_URL,
    LOG_CONNEXION_URL,
    ORGANIZATION_SUBSCRIPTIONS,
    ORGANIZATIONS,
    TIME_DISPONIBILITIES,
    SCREENING_FILES,
    FAQ_CATEGORIES,
    PATIENT_SETTINGS_URL,
    USER_REQUESTS,
    PARTNERS,
    GET_LINK_WITH_DOCTOR
}